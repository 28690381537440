import React from "react";
import Project from "./project";
import { GatsbyImage } from "gatsby-plugin-image";
import '../styles/page-header.scss';

const GeneralPageHeader = (props) => {
  const { secondHeading, firstHeading, description, heroImage } = props;
  //console.log(pageHeroImage)
  return (
    <div className="page-header">
      <div className="page-info">
        <div className="page-content">
          <h1 className="fs-primary-heading-alternative">{firstHeading}</h1>
          <p className="description fs-large-body">{secondHeading}</p>
          <p className="title-description">{description}</p>
        </div>
      </div>
      <div className="page-image">
        <GatsbyImage
          key={firstHeading}
          image={heroImage.gatsbyImageData}
          placeholder="blurred"
          className="logo"
          //style={{ marginRight: isMobile ? '0' : '2rem' }}
          formats={["auto", "webp", "avif"]}
          alt={`${firstHeading}`}
          transformOptions={{ fit: "cover", cropFocus: "attention" }}
        />

      </div>

    </div>
  );
};

export default GeneralPageHeader;