import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import Header from "../components/header";
import { HelmetDatoCms } from "gatsby-source-datocms";
import '../styles/general-page.scss';
import GeneralPageHeader from "../components/general-page-header";
import GeneralContent from "../components/general-content";
import Footer from "../components/footer";

export default function GeneralPage({ data: { site, page } }) {
  const { secondHeading, firstHeading, description, heroImage, textHeading, textDescription } = page;

  console.log(page)
  return (
    <Container>
      <Header />
      <main>
        <section className="general-page">
          <GeneralPageHeader
            firstHeading={firstHeading}
            secondHeading={secondHeading}
            description={description}
            heroImage={heroImage}
          />

        </section>
        <GeneralContent title={textHeading} descriptionText={textDescription} />
      </main>
      <HelmetDatoCms seo={page.seo} favicon={site.favicon} />
      <Footer />
    </Container>
  );
}

export const query = graphql`
  query GeneralPage($id: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsGeneralPage(id: { eq: $id }) {
      secondHeading
      textDescription
      textHeading
      slug
      firstHeading
      description
      heroImage {
            gatsbyImageData(width: 1500)
      }
  }
        
  }
`;
