import React from "react";
import '../styles/general-content.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import AnimateIn from "../utilites/AnimateIn";
import ReactMarkdown from 'react-markdown'
const GeneralContent = ({ title, subtitle, descriptionHeading, descriptionText, projectDuration, year, valueOfWork }) => {

  return (
    <div className="general-content">
      <div className="content-text">
        <div className="titles">
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
          <div className="description">
            <p><ReactMarkdown>{descriptionHeading}</ReactMarkdown></p>
            <span><ReactMarkdown>{descriptionText}</ReactMarkdown></span>
          </div>
        </div>


      </div>

      {/* <div className="stats">
        <div className="stat">
          <p className="fs-stat-text">{Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(valueOfWork)}</p>
          <span>Value Of Work</span>
        </div>
        <div className="stat">
          <p className="fs-stat-text">{year}</p>
          <span>Year</span>
        </div>

        <div className="stat">
          <p className="fs-stat-text">{projectDuration}</p>
          <span>Duration</span>
        </div>

      </div> */}
      {/* <Carousel
        showThumbs={true}
        autoPlay
        showArrows={false}
        interval={3000}
        showStatus={false}
        infiniteLoop
        width={'100%'}

        showIndicators={false}
      >

        {projectImages.length && projectImages.map((image, i) => {
          return <GatsbyImage
            key={data.title}
            image={image.gatsbyImageData}
            placeholder="blurred"

            className="logo"
            //style={{ marginRight: isMobile ? '0' : '2rem' }}
            formats={["auto", "webp", "avif"]}
            alt={`${data.title}`}
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
          />
        }
        )}
      </Carousel> */}

    </div>
  );
};

export default GeneralContent;
